import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import styled from 'styled-components'

const Head = styled.header`
  margin-top: 1.2rem;
  border-bottom: 1px solid #d6d6d6;
`

const Navigation = styled(Link)`
	box-shadow: none;
	background-image: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &:hover {
    background-image: none;
  }
`

const List = styled.ul`
  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  @media only screen and (max-width: 575.98px) {
    display: block;
    margin: 0;
  }
`

const ListItem = styled.li`
  flex-grow: ${({ shouldGrow }) => (shouldGrow ? 1 : null)};
  padding-left: 1rem;
  padding-right: 1rem;
`

const Title = styled.h3`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  color: ${(props) => (props.active ? '#26418f' : '')};
  cursor: ${(props) => (props.active ? 'default' : '')};

  &:hover {
    color: #26418f;
  }
`

const NavLink = styled(Title)`
  font-size: 1rem;
	opacity: 0.7;
  padding-bottom: ${(props) => (props.active ? '5px' : '7px')};
`

const Header = ({ path }) => (
	<Head>
		<List>
			<ListItem shouldGrow>
				<Navigation to="/">
					<Title active={ path === '/' }>
						Shariful Nibir
					</Title>
				</Navigation>
			</ListItem>
			<ListItem>
        <Navigation to="/blog">
          <NavLink active={ path === '/blog' }>Blog</NavLink>
        </Navigation>
				<Navigation to="/about">
					<NavLink active={ path === '/about' }>About</NavLink>
				</Navigation>
			</ListItem>
		</List>
	</Head>
)

Header.propTypes = {
  path: PropTypes.string.isRequired,
}

export default Header
