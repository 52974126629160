import React from "react"
import styled from 'styled-components'

import Header from './header'
import Footer from './footer'

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
`

const Content = styled.div`
  flex-grow: 1;
  margin: 3rem auto;
  max-width: 740px;
  width: calc(100% - 3em);
`

const Layout = ({ link, children }) => {
  return (
    <Container>
      <Header path={ link.pathname } />
      <Content>{ children }</Content>
      <Footer />
    </Container>
  )
}

export default Layout
