import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faTwitter, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const Foot = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
  margin: 1rem auto;
  max-width: 740px;
  width: calc(100% - 3em);
`

const Copyright = styled.span`
  font-size: 0.8rem;
  flex-grow: 1;
`

const Link = styled.a`
	background-image: none;
	margin: 5px;

  &:hover {
    background-image: none;
  }
`

const Footer = () => (
  <Foot>
    <Copyright>
      <span role="img" aria-label="Copyright">
        &copy;
      </span>
      {' '}
      {new Date().getFullYear()}, Shariful Nibir
    </Copyright>
    <Link
      href="https://facebook.com/shariful.nibir"
      target="_blank"
      rel="noopener"
      title="Facebook"
    >
      <FontAwesomeIcon icon={faFacebook} color="grey" />
    </Link>
    <Link
      href="https://www.instagram.com/shariful_nibir"
      target="_blank"
      rel="noopener"
      title="Instagram"
    >
      <FontAwesomeIcon icon={faInstagram} color="grey" />
    </Link>
    <Link
      href="https://twitter.com/nibirsharif"
      target="_blank"
      rel="noopener"
      title="Twitter"
    >
      <FontAwesomeIcon icon={faTwitter} color="grey" />
    </Link>
    <Link
      href="https://github.com/nibirsharif"
      target="_blank"
      rel="noopener"
      title="GitHub"
    >
      <FontAwesomeIcon icon={faGithub} color="grey" />
    </Link>
    <Link
      href="https://www.linkedin.com/in/shariful-nibir"
      target="_blank"
      rel="noopener"
      title="LinkedIn"
    >
      <FontAwesomeIcon icon={faLinkedin} color="grey" />
    </Link>
  </Foot>
)

export default Footer
